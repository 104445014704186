<template>
	<div
		v-if="items"
		class="table"
	>
		<table>
			<thead>
				<tr>
					<th />
					<th>Name</th>
					<th
						v-for="field in fields"
						:key="field.slug"
						:class="field.slug"
						:align="(field.type === 'char') ? 'char' : null"
					>
						{{ field.name }}
					</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="item in items"
					:key="item.name"
				>
					<td>
						<figure @click="onOpenModal(item)">
							<BaseIcon :src="'animals/' + (item.icon || item.slug + '.svg')" />
						</figure>
					</td>
					<td>
						<h4 @click="onOpenModal(item)">
							<strong>{{ item.name }}</strong>
							<span v-if="item.alias">{{ item.alias }}</span>
						</h4>
					</td>
					<td
						v-for="{isFilter, name, slug, type} in fields"
						:key="slug"
						:class="slug"
						:align="(type === 'char') ? 'char' : null"
					>
						<BaseFieldValue
							v-bind="{isFilter, name, slug, value: item[slug]}"
						/>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import {ModalMixin} from '@/mixins';
import {fields} from '@/data/filter';


export default {
	mixins: [ModalMixin],
	props: {
		items: {
			type: Array,
			default: () => []
		}
	},
	computed: {
		fields() {
			return fields;
		}
	}
};
</script>

<style lang="scss">
	.table {
		overflow-x: auto;

		table {
			margin: 0 auto;
			font-size: rem(12);
			border-spacing: 0;
			border-collapse: collapse;
		}

		th,
		td {
			padding: 0.5rem;
			text-align: left;
			vertical-align: top;

			&[align='char'] {
				text-align: right;
			}

			&.taxon {
				max-width: 300px;
			}

			strong {
				display: block;
			}
		}

		th {
			border-bottom: 1px solid currentColor;
		}

		h4 {
			cursor: pointer;
			font: inherit;
		}

		figure {
			cursor: pointer;
			margin: 0;
		}

		svg {
			width: 2rem;
			max-width: none;
			height: 2rem;
			fill: $color-brand;
		}

		tr:hover td {
			background-color: $color-border;
		}
	}
</style>
